<template>
  <div class="page">
    <div class="heading">
      <v-toolbar :flat="flatApp" dense>
        <v-btn icon @click="menuLink">
          <v-icon>mdi-menu</v-icon>
        </v-btn>
        <v-breadcrumbs
            :items="crumbs"
            class="capitalize"
            style="padding: 14px 20px"
        >
          <template v-slot:divider>
            <v-icon>mdi-chevron-right</v-icon>
          </template>
        </v-breadcrumbs>
        <v-spacer></v-spacer>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn color="primary" icon @click="goBack" v-on="on">
              <v-icon>mdi-keyboard-return</v-icon>
            </v-btn>
          </template>
          <span>Return to Request Page</span>
        </v-tooltip>
      </v-toolbar>
      <v-divider></v-divider>

      <div class="body">
        <v-card flat>
          <v-toolbar dense flat>
            <v-toolbar-title>
              <span style="color: #b2b2b2">Client</span>: {{ stuff.salut }} {{ stuff.first_name }} {{ stuff.mid_name }}
              {{ stuff.last_name }}
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn color="primary" text v-on="on" @click.stop="detailDialog = true">
                  <v-icon>
                    mdi-eye-outline
                  </v-icon>
                </v-btn>
              </template>
              <span>
                  Request Details
                </span>
            </v-tooltip>
            <RequestPreview
                :detailDialog="detailDialog"
                :myId="stuff.id"
                :request="stuff.laboratory_requestid"
                @closeView="closeView"
            />
          </v-toolbar>
          <v-divider></v-divider>
          <v-card-text>

            <v-row v-for="(input, k) in inputs" :key="k">
              <v-col cols="12" sm="3">
                <v-select v-model="input.serviceId"
                          :hint="`${currency(input.serviceId.pricing)}, ${input.serviceId.name}`"
                          :items="serviceList"
                          item-text="name"
                          item-value="id" label="Select Service"
                          persistent-hint return-object></v-select>
              </v-col>
              <v-col cols="12" sm="2">
                <v-text-field v-model="input.pricing" label="Price" type="number"></v-text-field>
              </v-col>
              <v-col cols="12" sm="3">
                <v-textarea v-model="input.comment" label="Details" outlined rows="1"
                            style="font-size: .8em"></v-textarea>
              </v-col>
              <v-col cols="12" sm="3">
                <v-textarea v-model="input.instructions" label="instructions" outlined rows="1"
                            style="font-size: .8em"></v-textarea>
              </v-col>
              <v-col cols="12" sm="1">
                <v-btn
                    v-show="k || (!k && inputs.length > 1)"
                    color="error" icon
                    @click="remove(k)"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-btn v-show="k == inputs.length - 1" color="primary" icon @click="add(k)">
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" sm="6">
                <v-menu
                    v-model="menu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    min-width="auto"
                    offset-y
                    transition="scale-transition"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        v-model="more.appointmentDate"
                        label="Appointment Date Schedule"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                      v-model="more.appointmentDate"
                      @input="menu = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" sm="6">
                <v-menu
                    ref="menu1"
                    v-model="menu1"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    :return-value.sync="time"
                    max-width="290px"
                    min-width="290px"
                    offset-y
                    transition="scale-transition"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        v-model="more.appTime"
                        label="Time for date Schedule"
                        prepend-icon="mdi-clock-time-four-outline"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                    ></v-text-field>
                  </template>
                  <v-time-picker
                      v-if="menu1"
                      v-model="more.appTime"
                      full-width
                      @click:minute="$refs.menu1.save(more.appTime)"
                  ></v-time-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>

        <v-toolbar dense flat style="margin: 8px 0">
          <v-toolbar-title>Selected Offer Summary</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn color="primary" small text @click.stop="submit">Submit</v-btn>
        </v-toolbar>

        <v-row>
          <v-col cols="12" sm="8">
            <v-card flat>
              <v-card-text>
                <v-data-table
                    :headers="headers"
                    :items="inputs"
                    :items-per-page="10"
                    :loading="loading"
                    loader-height="1"
                >
                  <template v-slot:item.serviceId="{ item }">
                    {{ item.serviceId.name }}
                  </template>
                  <template v-slot:item.pricing="{ item }">
                    {{ currency(item.pricing) }}
                  </template>
                </v-data-table>
              </v-card-text>
            </v-card>

          </v-col>
          <v-col cols="12" sm="4">
            <v-card flat>
              <v-card-text>
                <div class="my_text_list">
                  <ul>
                    <li>
                      <div class="title">Appointment Date</div>
                      <div class="text">{{ more.appointmentDate | myDate }}</div>
                    </li>
                    <li>
                      <div class="title">Appointment Time Schedule</div>
                      <div class="text">{{ more.appTime | myTime }}</div>
                    </li>
                    <li>
                      <div class="title">total cost</div>
                      <div class="text">{{ currency(totalSum()) }}</div>
                    </li>
                  </ul>
                </div>
              </v-card-text>
            </v-card>

          </v-col>
        </v-row>


      </div>

    </div>
    <v-snackbar
        v-model="snack.bar"
        :color="snack.color"
        :multi-line="snack.multiLine"
    >
      {{ snack.text }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snack.bar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import WinScroll from "../../mixins/windowscroll";
import Utils from "../../mixins/utils";
import moment from "moment";
import Restful from "@/services/RestFul";
import RequestPreview from "../../components/subsidiaries/labs/preview.vue";

export default {
  name: "answers",
  props: {
    d: {type: String},
    req: {type: String}
  },
  components: {RequestPreview},
  mixins: [Utils, WinScroll("position")],
  data: () => ({
    snack: {
      color: "info",
      multiLine: true,
      bar: false,
      text: "Processing ... ",
    },
    crumbs: [
      {
        link: true,
        text: "Dashboard",
        to: {name: "lab.dash"},
      },
      {
        link: true,
        text: "Request",
        to: {name: "lab.request"},
      },
      {
        link: true,
        text: "lab request response",
        to: {name: "lab.request.accept"},
      },
    ],
    stuff: {},
    detailDialog: false,
    serviceList: [],
    loading: false,
    inputs: [
      {
        serviceId: 0,
        comment: null,
        pricing: null,
        instructions: null,
        laboratoryRequestId: null
      },
    ],
    headers: [
      {text: "service type", value: "serviceId"},
      {text: "pricing", value: "pricing", align: 'center'},
      {text: "detail", value: "comment"},
      {text: "instructions", value: "instructions"},
    ],
    menu: false,
    menu1: false,
    menu2: false,
    time: null,
    time1: null,
    more: {
      appointmentDate: null,
      appTime: null
    },
  }),
  // watch: {
  //   'more.appTime': function (a) {
  //     console.log(a);
  //   }
  // },
  computed: {
    flatApp: function () {
      if (this.position[1] > 30) {
        return false;
      } else {
        return true;
      }
    },
  },
  created() {
    this.das();
    this.subList();
  },
  methods: {
    das() {
      let json = JSON.parse(this._decode(this.d));
      this.stuff = json;
      console.log('stuff:', this.stuff)
    },
    subList() {
      this.loading = true;
      let self = this;
      Restful.subscribers.subservice
          .list(this.stuff.subscriber_subsidiariesid)
          .then((response) => {
            console.log(response.data);
            if (response.status === 200) {
              this.serviceList = response.data;
            }
            this.loading = false;
          })
          .catch((error) => {
            console.log(error.response);
            self.loading = false;
          });
    },
    menuLink() {
      this.$emit("sidebar");
    },
    goBack() {
      window.history.length > 1 ? this.$router.go(-1) : this.$router.push('/')
    },
    closeView(p) {
      this.detailDialog = p.state;
    },
    add(index) {
      console.log("index" + index);
      this.inputs.push({
        serviceId: [],
        comment: "",
        pricing: null,
        instructions: null,
        laboratoryRequestId: null
      });
      this.totalSum()
    },
    remove(index) {
      this.inputs.splice(index, 1);
    },
    totalSum() {
      let sum = 0;
      for (let i = 0; i < this.inputs.length; i++) {
        // console.log('px: ', this.inputs[i].pricing);
        sum += parseInt(this.inputs[i].pricing);
      }
      console.log('sum', sum)
      return sum;
    },
    submit() {
      this.snack.bar = true;
      this.snack.text = "Processing ..."
      let data = this.inputs.map(({serviceId, ...rest}) => {
        return {
          ...rest,
          id: serviceId.id,
          pricing: parseInt(rest.pricing),
          laboratoryRequestId: parseInt(this._decode(this.req))
        };
      });
      let labProspectingId = this.stuff.id;
      let appDate = this.more.appointmentDate;
      let appTime = this.more.appTime;

      let appDateTime = new Date(appDate + " " + appTime);
      let appointmentDate = moment(appDateTime).valueOf();
      console.log('appointmentDate', appointmentDate)

      let jsonData = data;
      let da = {jsonData, labProspectingId, appointmentDate};
      console.log('payload:', da)
      console.log('payload:', JSON.stringify(da))

      let self = this;
      Restful.medics.treatment.labResponse
          .upload(da)
          .then((response) => {
            console.log(response.data);
            if (response.status === 200) {
              this.snack.color = "success";
              this.snack.text = response.data
              //
              setTimeout(() => {
                this.$router.push({name: 'lab.request'})
              }, 2000);
            }
            this.loading = false;
          })
          .catch((error) => {
            console.log(error.response);
            self.loading = false;
          });
    }
  }
}
</script>